import * as React from "react";
import { AppProps } from "next/app";
import "@styles/global.css";
import {
    type DehydratedState,
    QueryClient,
    QueryClientProvider,
} from "react-query";
import { Hydrate } from "react-query/hydration";
import { MantineProvider } from "@mantine/core";
import { ReactQueryDevtools } from "react-query/devtools";
import "react-quill/dist/quill.snow.css";
import { Notifications } from "@mantine/notifications";

function MyApp({
    Component,
    pageProps,
}: AppProps<{ dehydratedState: DehydratedState }>): JSX.Element {
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
                <MantineProvider
                    withGlobalStyles
                    withNormalizeCSS
                    theme={{
                        breakpoints: {
                            xs: "500",
                            sm: "800",
                            md: "1000",
                            lg: "1200",
                            xl: "1400",
                        },
                        colorScheme: "light",
                    }}
                >
                    <Notifications position="top-center" />
                    <Component {...pageProps} />
                </MantineProvider>
            </Hydrate>
            {/* <ReactQueryDevtools initialIsOpen={false}  /> */}
        </QueryClientProvider>
    );
}

export default MyApp;
